@import "bootstrap.min";


:root {
    --bs-body-color: #333333;
    --header-bg-color: rgba(116, 176, 216, 0.85);
    --dark-bg-color: rgba(116, 176, 216, 1);
    --highlight-color: orange;
}

$active-nav-border: 2px solid #4F5F6F;
$margin-top: 5rem;

body {
    margin-top : $margin-top + 1rem;
    background-color: #CCCFD8;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 6rem);
    font-family: 'Roboto', sans-serif;
}

article {
    flex-grow: 1;
    div.errorpage {
        height: 100%;
        font-family: 'Pixelify Sans', sans-serif;
        font-size: xx-large;
    }

    blockquote {
        margin-left: 3em;
        p {
            &::before, &::after {
                content: "\"";
            }
            font-style: italic;
            margin-bottom: 0;
        }
        margin-bottom: 0;
    }
    p.citeref {
        &::before {
            content: "aus: ";
        }
        margin-left: 4em;
    }
   
}

.post-content table {
    border: 1px solid grey;
    border-collapse: collapse;
    th {
        padding: 0.75em;
    }
    td {
        padding: 0.75em;
        border: 1px solid grey;
    }
}


img {
    &.andre { 
        border-radius: 50%;    
        margin: 4rem 2rem 0 0;
    }
    &.status_image { 
        position: fixed;
        top: 1%;
        left: 40%;
        z-index: 2000;
        width: 7em;
        height: 7em;
    }
}
div.andre {
    margin-top: 4rem;
    h1 {
        font-size: 3rem;
        font-weight: lighter;
        &:first-child {
            margin-bottom: -0.4rem;
        }
    }
    >div hr {
        display: inline-block;
        margin-right: 0.5em;
        border: 1px solid black;
        width: 3rem;
    }
    h2 {        
        display: inline-block;
        vertical-align: text-bottom;
        font-weight: 100;
        font-size: 1.3rem;
    }
}

footer {
    background-color: var(--header-bg-color);
    xmargin-top: 0.3rem;
}

nav {
    background-color: var(--header-bg-color);
    a {
        text-decoration: none;
        color: var(--bs-body-color); 
        &.nav-headline {
            font-family: Poppin, sans-serif;
            font-size: 2.2rem;
        }
        &.nav-subline { 
            font-family: Montserrat, sans-serif;
            font-size: 0.9rem;
        }
    }

    .nav-item {
        margin: 0.3rem;
        &:hover {
            .nav-link {
                border-bottom: 2px solid var(--highlight-color);
                border-radius: 0.5rem;
            } 
        }   
        &:hover {
            .nav-link.active {
                border-bottom: $active-nav-border;
            }
        }   
 
    }
    .nav-link.active {
        border: $active-nav-border;
        border-radius: 0.5rem;
    }
    .nav-link {
        border: 2px solid rgba(255, 255, 255, 0);
        border-radius: 0.5rem;
    }

}

ul.post-list {
    list-style-type: none;
    li { 
        margin: 1rem;
    }
    .card-body {
        background-color: #BDBFCB;
    }
}

figure.highlight {
    font-family: "Menlo", "Inconsolata", "Consolas", "Roboto Mono", "Ubuntu Mono", "Liberation Mono", "Courier New", monospace;
    font-size: 0.9375rem;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    background-color: #eeeeff;

    pre {
        padding: 8px 12px;
        overflow-x: auto;

        code.language-java {
            .kd, .k {
                font-weight: bolder;
                color: blue;
            }
            .kc {
                color: darkcyan;
            }
            .nc, .kt {
                font-weight: bolder;
            }
            .n {
                color: darkorchid;
                font-weight: bold;
            }
            .na {
                font-style: italic;
            }
            .s, .sh {
                color : green;
            }
            .c1 {
                color: gray;
            }
        }
    }
}
